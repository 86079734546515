import React, { useContext, useEffect, useState } from 'react';
import { Redirect, Route, Link, Switch } from 'react-router-dom';
import {
	IonApp,
	IonHeader,
	IonToolbar,
	IonTitle,
	IonContent,
	IonMenu,
	IonList,
	IonListHeader,
	IonMenuToggle,
	IonIcon,
	IonLabel,
	IonPage,
	IonButtons,
	IonButton,
	IonItem,
	IonMenuButton,
	IonTabs,
	IonRouterOutlet,
	IonTabButton,
	IonTabBar,
	IonBadge,
	IonFab,
	IonFabButton,
	IonFooter
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import { setupIonicReact } from '@ionic/react';

import {
	albums, home, logInOutline, logOut, lockOpenOutline, cart,
	notificationsOutline, listCircleOutline, settingsSharp, paperPlane,
	homeOutline, receiptOutline, receipt, list, bagAdd, logoGooglePlaystore, logoAppleAppstore,
	downloadSharp, bagCheck,
	downloadOutline, shareOutline,
	download, openOutline, documentAttachOutline, documentAttachSharp, documentAttach,
	boatOutline, boat
} from 'ionicons/icons';

import { PushNotifications } from '@capacitor/push-notifications';
import { Capacitor } from '@capacitor/core';

import Home from './pages/Home';

import Auth from './pages/Auth';

import Admin from './pages/admin/Admin';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import './App.css';

import { fetchData } from './data/fetcher';
import CategoryProducts from './pages/CategoryProducts';
import Product from './pages/Product';
import FavouriteProducts from './pages/FavouriteProducts';
import CartProducts from './pages/CartProducts';
import MyProducts from './pages/MyProducts';
import SearchProducts from './pages/SearchProducts';
import Checkout from './pages/Checkout';
import TermsAndConditions from './pages/Terms';
import TrackOrder from './pages/TrackOrder';

import AuthContext from "./UserContext";
import MyAccount from "./pages/MyAccount.tsx";
import MyOrders from "./pages/MyOrders.tsx";
import MyPurchases from "./pages/MyPurchases.tsx";

import NotificationButton from './components/Notification';
import Wallet from './components/Wallet';
import BuyNow from './pages/BuyNow';

import NotFound from './components/NotFound';

import InstallButton from './components/InstallPwaButton';

setupIonicReact();

const App = () => {

	const { authValues, logout } = useContext(AuthContext);

	function handleLogOut() {

		logout();

		window.location.reload();

	}

	useEffect(() => {

		fetchData();

		// Add reCaptcha
		const script = document.createElement('script');
		script.src = "https://www.google.com/recaptcha/enterprise.js?render=6Lf2DOopAAAAAKrW9hrL8_oxVXQrhxm3cYIbYrgd";
		script.async = true;
		document.body.appendChild(script);
		return () => {
			document.body.removeChild(script);
		}

	}, []);

	const [isAndroid, setIsAndroid] = useState(false);
	const [isIOS, setIsIOS] = useState(false);

	useEffect(() => {

		const userAgent = navigator.userAgent || navigator.vendor || window.opera;

		// Detect Android
		if (/android/i.test(userAgent)) {
			setIsAndroid(true);
		}

		// Detect iOS
		if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
			setIsIOS(true);
		}
	}, []);

	const isAppInstalled = () => {
		// Check if the app is installed on iOS
		if (window.navigator.standalone) {
			return true;
		}

		// Check if the app is installed on Android
		let isInstalled = false;
		window.addEventListener('beforeinstallprompt', (e) => {
			e.preventDefault();
			isInstalled = true;
		});

		return isInstalled;
	};

	const appInstalled = isAppInstalled();

	let deferredPrompt;

	window.addEventListener('beforeinstallprompt', (e) => {
		// Prevent the mini-infobar from appearing on mobile
		e.preventDefault();
		// Stash the event so it can be triggered later.
		deferredPrompt = e;
		// Optionally, update your UI to indicate that the app can be installed
	});

	// Later, when you want to trigger the install prompt:
	if (deferredPrompt) {
		deferredPrompt.prompt();
		deferredPrompt.userChoice.then((choiceResult) => {
			if (choiceResult.outcome === 'accepted') {
				console.log('User accepted the install prompt');
			} else {
				console.log('User dismissed the install prompt');
			}
			deferredPrompt = null;
		});
	}

	let isProduction = process.env.REACT_APP_ENV === 'production';

	useEffect(() => {

		const addListeners = async () => {

			await PushNotifications.addListener('pushNotificationReceived', notification => {
				console.log('Push notification received: ', notification);
			});

			await PushNotifications.addListener('pushNotificationActionPerformed', notification => {
				console.log('Push notification action performed', notification.actionId, notification.inputValue);
			});
		};

		const getDeliveredNotifications = async () => {
			const notificationList = await PushNotifications.getDeliveredNotifications();
			console.log('delivered notifications', notificationList);
		};

		if (Capacitor.isNativePlatform()) {
			// Call the functions to set up push notifications only on native platforms
			addListeners();

			// Optionally, you can call getDeliveredNotifications if needed
			getDeliveredNotifications();
		} else {
			// Handle web push notifications separately if needed
		}
	}, []);

	return (
		<IonApp>
			<IonPage className="ion-page" id="main-content">
				<IonReactRouter>
					<IonHeader>
						<IonToolbar>
							<IonButtons slot="start">
								<IonButton slot="start">
									<IonMenuButton color="drukre"></IonMenuButton>
								</IonButton>
								<Link to="/">
									<IonButton color="drukre">
										<IonLabel><IonIcon icon={homeOutline}></IonIcon> &nbsp;EThrom {isProduction ? '' : ' - Development'}</IonLabel>
									</IonButton>
								</Link>
							</IonButtons>
							{authValues.authenticated == false ?
								<IonButtons slot="end" class="ion-padding-horizontal">
									<Link to="/login" className="header-link">
										<IonButton id="open-login">
											<IonIcon color="drukre" icon={logInOutline}></IonIcon>
											<IonLabel class="ion-hide-md-down">Log In</IonLabel>
										</IonButton>
									</Link>|
									<Link to="/register" className="header-link">
										<IonButton id="open-login">
											<IonIcon color="drukre" icon={lockOpenOutline}></IonIcon>
											<IonLabel class="ion-hide-md-down">Register</IonLabel>
										</IonButton>
									</Link>
								</IonButtons>
								:
								<IonButtons slot="end" class="ion-padding-horizontal">
									<Link to="/my-products" className="header-link">
										<IonButton class="ion-hide-md-down">
											<IonIcon color="drukre" icon={listCircleOutline} />
											<IonLabel>My Products</IonLabel>
										</IonButton>
									</Link>
									<Link to="/my-orders" className="header-link">
										<IonButton class="ion-hide-md-down">
											<IonIcon color="drukre" icon={documentAttachOutline} />
											<IonLabel>Orders for me</IonLabel>
										</IonButton>
									</Link>
									<span className="ion-hide-md-down">
										&nbsp; <NotificationButton /> &nbsp;
									</span>
									<Link to="/myaccount" className="header-link">
										<IonButton class="ion-hide-md-down">
											<IonIcon color="drukre" icon={settingsSharp} />
											<IonLabel>Account</IonLabel>
										</IonButton>
									</Link>
									<Link to="#" className="header-link" onClick={handleLogOut}>
										<IonButton onClick={() => handleLogOut()}>
											<IonIcon color="drukre" icon={logOut} />
											<IonLabel>Log Out</IonLabel>
										</IonButton>
									</Link>
								</IonButtons>
							}
						</IonToolbar>
					</IonHeader>
					<IonMenu content-id="main">
						<IonHeader>
							<IonToolbar color="drukre">
								<IonTitle>Menu</IonTitle>
							</IonToolbar>
						</IonHeader>

						<IonContent>
							<IonList>
								<IonListHeader>

								</IonListHeader>
								<IonMenuToggle auto-hide="false">
									<IonItem button routerDirection="none" routerLink="/home" routerLinkActive="active">
										<IonIcon color="drukre" slot="start" icon={home}></IonIcon>
										<IonLabel>Home</IonLabel>
									</IonItem>
									<IonItem button routerLink="/categories">
										<IonIcon color="drukre" slot="start" icon={albums}></IonIcon>
										<IonLabel>
											Categories
										</IonLabel>
									</IonItem>
									<IonItem button routerLink="/cart">
										<IonIcon color="drukre" slot="start" icon={cart}></IonIcon>
										<IonLabel>
											Cart
										</IonLabel>
									</IonItem>
									
									<IonItem button routerLink="/track-order">
										<IonIcon color="drukre" slot="start" icon={boat}></IonIcon>
										<IonLabel>
											Track Order
										</IonLabel>
									</IonItem>
									{authValues.authenticated ?
										<>
											<IonItem button routerLink="/my-products">
												<IonIcon color="drukre" slot="start" icon={list}></IonIcon>
												<IonLabel>
													My Products
												</IonLabel>
											</IonItem>
											<IonItem button routerLink="/my-purchases">
												<IonIcon color="drukre" slot="start" icon={receipt}></IonIcon>
												<IonLabel>
													My Purchases
												</IonLabel>
											</IonItem>
										</>
										: null}

									{authValues.authenticated ?
										<IonItem button routerLink="/my-products">
											<IonIcon color="drukre" slot="start" icon={bagCheck}></IonIcon>
											<IonLabel>
												Sell Yours
											</IonLabel>
										</IonItem>
										: <IonItem button routerLink="/login">
											<IonIcon color="drukre" slot="start" icon={bagCheck}></IonIcon>
											<IonLabel>
												Sell Yours
											</IonLabel>
										</IonItem>}
								</IonMenuToggle>
							</IonList>
						</IonContent>
						<IonFooter style={{ marginBottom: '100px' }}>
							{authValues.authenticated ?
								<Wallet />
								:
								null}
							<IonContent className="ion-padding"
								style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
								<IonItem horizontal="end" vertical="center" slot="fixed">
									{isAndroid && (
										appInstalled ? (
											<IonButton href="your-app-url" target="_blank">
												<IonIcon icon={logoGooglePlaystore}></IonIcon> Access on PlayStore <IonIcon icon={openOutline}></IonIcon>
											</IonButton>
										) : (
											<IonButton href="https://play.google.com/store/apps/details?id=org.ethrom" target="_blank">
												<IonIcon icon={logoGooglePlaystore}></IonIcon> Access on PlayStore <IonIcon icon={downloadOutline}></IonIcon>
											</IonButton>
										)
									)}
									{isIOS && (
										appInstalled ? (
											<IonButton href="https://apps.apple.com/us/app/ethrom/id6736821139" target="_blank">
												<IonIcon icon={logoAppleAppstore}></IonIcon> Access on AppStore <IonIcon icon={openOutline}></IonIcon>
											</IonButton>
										) : (
											<IonButton href="https://apps.apple.com/us/app/ethrom/id6736821139" target="_blank">
												<IonIcon icon={logoAppleAppstore}></IonIcon> Access on AppStore <IonIcon icon={downloadOutline}></IonIcon>
											</IonButton>
											/*
											<p>To install this app, tap the <IonIcon icon={shareOutline} /> button and then "Add to Home Screen".</p>
											*/
										)
									)}
									{!isAndroid && !isIOS && (
										<InstallButton />
									)}
								</IonItem>
							</IonContent>
						</IonFooter>
					</IonMenu>
					<IonTabs>
						<IonRouterOutlet id="main" swipeGesture={true}>
							<Switch>
								{/*authValues.authenticated ?
								<Route exact path="/agentmap" component={AgentMap} />
								: 
								<Route exact path="/agentmap" render={()=> <Redirect to="/login" />} />
								*/}<Route path="/" exact={true} component={Home}>
								</Route>
								<Route path="/home" exact={true} component={Home}>
								</Route>
								<Route path="/categories" exact={true}>
									<Home />
								</Route>

								<Route path="/favourites" exact>
									<FavouriteProducts />
								</Route>

								<Route path="/cart" exact>
									<CartProducts />
								</Route>

								<Route path="/category/:slug" exact>
									<CategoryProducts />
								</Route>

								<Route path="/category/:slug/:id" exact>
									<Product />
								</Route>

								<Route path="/search" exact>
									<SearchProducts></SearchProducts>
								</Route>

								<Route path="/terms-and-conditions">
									<TermsAndConditions />
								</Route>

								<Route exact path='/login' component={Auth} />
								<Route exact path='/register' component={Auth} />
								<Route exact path='/resetpassword' component={Auth} />
								<Route exact path='/verifyemail/:code' component={Auth} />
								<Route exact path='/chanel/:code' component={Auth} />
								<Route exact path='/myaccount' component={MyAccount} />
								<Route exact path='/my-orders' component={MyOrders} />
								<Route exact path='/admin' component={Admin} />
								<Route exact path='/my-products' component={MyProducts} />
								<Route path="/checkout" component={Checkout} exact={true} />
								<Route exact path='/my-purchases' component={MyPurchases} />
								<Route exact path="/buynow" component={BuyNow} />
								<Route exact path="/track-order" component={TrackOrder} />
								<Route path="*">
									<NotFound />
								</Route>
							</Switch>
						</IonRouterOutlet>
						{authValues.authenticated ?
							<IonTabBar slot="bottom" class="ion-hide-md-up">
								<IonTabButton tab="myproducts" href="/my-products">
									<IonIcon color="drukre" icon={listCircleOutline} />
									<IonLabel>My Products</IonLabel>
								</IonTabButton>
								<IonTabButton tab="myorders" href="/my-orders">
									<IonIcon color="drukre" icon={documentAttachOutline} />
									<IonLabel>Orders for me</IonLabel>
								</IonTabButton>
								<IonTabButton tab="notification">
									<NotificationButton />
									<IonLabel>Notifications</IonLabel>
								</IonTabButton>
								<IonTabButton tab="account" href="/myaccount">
									<IonIcon color="drukre" icon={settingsSharp} />
									<IonLabel>Account</IonLabel>
								</IonTabButton>
							</IonTabBar>
							:
							<IonTabBar slot="bottom" class="ion-hide-md-up">
								<IonTabButton tab="cart" href="/cart">
									<IonIcon color="drukre" icon={cart}></IonIcon>
									<IonLabel>Cart</IonLabel>
								</IonTabButton>
								<IonTabButton tab="notification" href="/login">
									<IonIcon color="drukre" icon={logInOutline}></IonIcon>
									<IonLabel>Login / Register</IonLabel>
								</IonTabButton>
							</IonTabBar>
						}
					</IonTabs>
				</IonReactRouter>
			</IonPage>
		</IonApp>
	)
}

export default App;
